import { Facebook, Instagram, Mail, Twitter } from "@mui/icons-material";
import { Hidden } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const [display, setDisplay] = useState(true);
  useEffect(() => {
    if (location.pathname === "/chat") {
      setDisplay(false);
    }
  }, [location.pathname]);

  return (
    <div
      className="h-[30vh] bg-gradient-to-r from-bgGradiant-primary to-bgGradiant-secondary flex-col items-center w-full"
      style={{ display: display ? "flex" : "none" }}
    >
      <div className="h-full bg-paper-main flex flex-row items-start justify-between w-full py-4 md:flex-nowrap flex-wrap px-8">
        {/* <div className="sm:w-max w-1/2 flex flex-col items-start"> */}
        <img className="sm:w-max w-1/2" src="/assets/logo/logo.svg" alt="" />
        {/* </div> */}
        <div className="sm:w-max flex flex-col items-start space-y-4">
          <div className="font-semibold sm:text-base text-sm">
            Connect with us
          </div>
          <div className="flex items-center space-x-5">
            <Instagram fontSize="small" className="hover:scale-125" />
            <Facebook fontSize="small" className="hover:scale-125" />
            <Twitter fontSize="small" className="hover:scale-125" />
          </div>
        </div>
        <div className="w-max flex flex-col space-y-4 items-start sm:text-base text-sm">
          <div className="font-semibold">Need a help ?</div>
          <div className="flex flex-col items-start space-y-2 ">
            <div className="sm:text-sm text-xs">24x7 Support</div>
            <a
              className="sm:text-sm text-xs flex items-center space-x-2 w-full hover:text-primary hover:drop-shadow-sm"
              href="mailto: support@mindsync.com"
            >
              <Mail fontSize="small" />
              <div>support@mindsync.com</div>
            </a>
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-center font-semibold text-paper-main space-x-10 py-2 text-sm">
        <div className="cursor-default sm:text-base text-xs">
          © 2023 themindsync. All right reserved
        </div>
        <Hidden mdDown>
          <div>|</div>
          {/* <Divider flexItem orientation="vertical" sx={{ bgcolor: "white" }} /> */}
          <div className="hover:underline cursor-pointer">Privacy policy</div>
          <div>|</div>
          <div className="hover:underline cursor-pointer">Contact us</div>
        </Hidden>
      </div>
    </div>
  );
};

export default Footer;
