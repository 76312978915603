import scoreMetadata from "./metadata";

const vadprsScoreCalculator = ({ questions, assessment, results }) => {
  let totalScoreAvailable = 0;
  const questionsAnswers = [];
  let scoreGained = 0;

  let maxWeight = -1;
  questions.forEach((eachQuestion, index) => {
    eachQuestion.options.forEach((eachOption) => {
      if (eachOption.weight > maxWeight) {
        maxWeight = eachOption.weight;
      }
    });
    totalScoreAvailable += maxWeight;
  });

  for (const [questionId, optionId] of Object.entries(assessment)) {
    const question = questions.find(
      (eachQuestion) => eachQuestion._id === questionId
    );
    if (question) {
      const option = question.options.find(
        (eachOption) => eachOption._id === optionId
      );
      if (option) {
        questionsAnswers.push({
          answer_weight: option.weight,
          question: question.title,
          answer: option.optionTitle,
        });

        scoreGained += option.weight;
      }
    }
  }

  const result = {};
  scoreMetadata.forEach((diease, index) => {
    const ranges = diease.ranges;
    ranges.forEach((range) => {
      const score = questionsAnswers
        .slice(range.range.from - 1, range.range.to)
        .filter(
          (questionAnswer) => questionAnswer.answer_weight >= range.scoreGreater
        ).length;
      if (score < range.min) return;

      result[diease.slug] = {
        passedCondition: (result[diease.slug]?.passedCondition || 0) + 1,
      };
    });
  });

  const resultMessages = [];
  Object.entries(result).forEach(([key, value]) => {
    const foundDisease = scoreMetadata.find((diease) => diease.slug === key);
    if (value.passedCondition === foundDisease.ranges.length) {
      resultMessages.push(foundDisease.name);
    }
  });

  const calculatedResult = results.find(
    (eachResult) => scoreGained >= eachResult.more_than
  );

  return {
    totalScoreAvailable,
    scoreGained,
    questionsAnswers,
    calculatedResult,
    resultMessage: resultMessages.join(","),
  };
};

export default vadprsScoreCalculator;
