const scoreMetadata = [
  {
    name: "ADD (inattentive)",
    slug: "add",
    ranges: [
      {
        range: { from: 1, to: 9 },
        scoreGreater: 2,
        min: 6,
      },
      {
        range: {
          from: 48,
          to: 55,
        },
        scoreGreater: 4,
        min: 1,
      },
    ],
  },
  {
    name: "ADHD (hyperactive/impulsive)",
    slug: "adhd",
    ranges: [
      {
        range: { from: 10, to: 18 },
        scoreGreater: 2,
        min: 6,
      },
      {
        range: {
          from: 48,
          to: 55,
        },
        scoreGreater: 4,
        min: 1,
      },
    ],
  },
  {
    name: "ADHD Combined (inattentive/hyperactivity)",
    slug: "adhd_combined",
    ranges: [
      {
        range: { from: 1, to: 9 },
        scoreGreater: 2,
        min: 6,
      },
      {
        range: { from: 10, to: 18 },
        scoreGreater: 2,
        min: 6,
      },
      {
        range: {
          from: 48,
          to: 55,
        },
        scoreGreater: 4,
        min: 1,
      },
    ],
  },
  {
    name: "Oppositional Defiance",
    slug: "oppositional_defiance",
    ranges: [
      {
        range: { from: 19, to: 26 },
        scoreGreater: 2,
        min: 4,
      },
      {
        range: {
          from: 48,
          to: 55,
        },
        scoreGreater: 4,
        min: 1,
      },
    ],
  },
  {
    name: "Conduct Disorder",
    slug: "conduct_disorder",
    ranges: [
      {
        range: { from: 27, to: 40 },
        scoreGreater: 2,
        min: 3,
      },
      {
        range: {
          from: 48,
          to: 55,
        },
        scoreGreater: 4,
        min: 1,
      },
    ],
  },
  {
    name: "Anxiety/Depression",
    slug: "anxiety",
    ranges: [
      {
        range: { from: 41, to: 47 },
        scoreGreater: 2,
        min: 7,
      },
      {
        range: {
          from: 48,
          to: 55,
        },
        scoreGreater: 4,
        min: 1,
      },
    ],
  },
];

export default scoreMetadata;
