import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Typewriter from "typewriter-effect";
import * as yup from "yup";
import BaseHelper from "../components/BaseHelper";
import AssessmentInstance from "../services/assessmentService";
import scoreCalculator from "../utils/scoreCalculator";
import customScoreCalculator from "../utils/customScoreCalculator";
import customAssessments from "../customAssessments/custom.json";

const AssessmentPage = () => {
  const navigate = useNavigate();
  const { slug: assessmentSlug } = useParams();
  const [assessmentData, setAssessmentData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [invalidId, setInvalidId] = useState(false);

  useEffect(() => {
    if (assessmentSlug) {
      if (!!customAssessments[assessmentSlug]) {
        setAssessmentData(customAssessments[assessmentSlug]);
        setIsLoading(false);
        return;
      }
      fetchAssessment();
    }
  }, [assessmentSlug]);

  const fetchAssessment = async () => {
    setIsLoading(true);
    try {
      const assessment = await AssessmentInstance.getAssessmentBySlug(
        assessmentSlug
      );
      if (assessment.success && assessment.data !== null) {
        setAssessmentData(assessment.data);
        setIsLoading(false);
      } else {
        navigate("*");
        setInvalidId(true);
      }
    } catch (error) {
      navigate("*");
    }
  };

  if (invalidId) {
    return (
      <BaseHelper
        header={
          <div className="bg-primary h-screen flex items-center justify-center">
            <div className="bg-paper-main p-10 rounded-xl w-6/12 text-center">
              Invalid Test
            </div>
          </div>
        }
      />
    );
  }

  return !isLoading ? (
    <BaseHelper
      header={<AssessmentPageHeader heading={assessmentData} />}
      content={<AssessmentPageContent assessmentData={assessmentData} />}
    />
  ) : (
    <CircularProgress />
  );
};

const AssessmentPageHeader = ({ heading }) => {
  return (
    <div className="flex lg:flex-row lg:space-x-28 flex-col-reverse lg:items-start items-center w-full h-full lg:px-24 md:px-12 px-2 py-16">
      <Card sx={{ maxWidth: "25rem" }} className="lg:mt-0 mt-8">
        <CardMedia
          controls
          component="video"
          src={heading.doctors_video.s3Url}
          alt="Take A Test"
        />
      </Card>
      <div className="content flex flex-col space-y-6 items-start w-full lg:mt-6">
        <div className="text-paper-main lg:text-4xl md:text-3xl text-2xl font-semibold w-full flex items-center lg:justify-start justify-center drop-shadow-lg ml-8">
          {heading.assessment_title}
        </div>
        <div className="text-paper-main w-full font-medium lg:text-lg md:text-base text-xs text-start flex items-start space-x-3">
          <img
            className="w-10 object-contain rounded-full bg-gray-800"
            src="/assets/images/bot.png"
            alt=""
          />
          <Typewriter
            options={{
              strings: heading.assessment_identifier.trim(),
              autoStart: true,
              loop: false,
              delay: "natural",
              deleteSpeed: 0,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const AssessmentPageContent = ({ assessmentData }) => {
  const { questions, results } = assessmentData;
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const questionSchema = yup.object().shape(
    questions.reduce((schema, question) => {
      const optionIds = question.options.map((option) => option._id);
      return {
        ...schema,
        [question._id]: yup
          .string()
          .oneOf(optionIds, "Please select one option")
          .required("Please select one option"),
      };
    }, {})
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(questionSchema),
  });

  const onSubmit = (assessment) => {
    setIsSubmitLoading(true);
    let calculatedData = {};
    if (assessmentData?.custom_scoring) {
      calculatedData = customScoreCalculator({
        questions,
        assessment,
        results,
      });
    } else {
      calculatedData = scoreCalculator({ questions, assessment, results });
    }

    navigate(`/result/${assessmentData.assessment_slug}`, {
      state: {
        assessmentData,
        ...calculatedData,
      },
    });
  };

  const handleOptionClick = (questionId, optionId) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [questionId]: optionId,
    }));

    setValue(questionId, optionId);
  };

  return (
    <div className="lg:px-5 px-2 flex flex-col w-full">
      <div className="bg-paper-border rounded-2xl lg:px-6 px-2 mb-4">
        <div className="flex flex-col items-start lg:space-y-6 space-y-2 py-6">
          <p className="font-bold p-0 m-0">Notes: </p>
          <ul className="space-y-3 list-disc mx-6">
            <li>
              {assessmentData?.custom_scoring
                ? "Over the last 6 months"
                : "Over the last 2 weeks"}
              , how often have you been bothered by the following problems?`
            </li>
            <li>Please note, all fields are required.</li>
          </ul>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {questions.map((question, questionIndex) => (
          <div
            key={questionIndex}
            className="my-4 flex flex-col items-start space-y-5"
          >
            <div className="flex items-center space-x-2">
              <Typography className="font-montserrat">
                {questionIndex + 1}.{" "}
              </Typography>
              <Typography
                className="text-textColor-question lg:text-xl md:text-base text-sm hyphens-auto font-medium font-montserrat"
                dangerouslySetInnerHTML={{
                  __html: question.title,
                }}
              />
            </div>

            <div className="flex items-center flex-wrap ">
              {question.options.map((option, optionIndex) => (
                <button
                  key={optionIndex}
                  className={`mr-4 my-2 rounded-xl  p-2 px-3 hover:bg-textColor-secondary hover:text-paper-main focus:bg-action-select ${
                    selectedOptions[question._id] === option._id
                      ? "bg-action-select text-paper-main"
                      : "bg-paper-border"
                  }`}
                  type="button"
                  onClick={() => handleOptionClick(question._id, option._id)}
                >
                  <span className="font-medium lg:text-base md:text-sm text-xs">
                    {option.optionTitle}
                  </span>
                </button>
              ))}
            </div>
            {errors[`${question._id}`] && (
              <p className="text-error-main text-xs">
                * {errors[`${question._id}`].message}
              </p>
            )}
            <input
              type="hidden"
              value={selectedOptions[question._id] || ""}
              {...register(question._id)}
            />

            {questionIndex < questions.length - 1 && <Divider flexItem />}
          </div>
        ))}
        <div className="flex items-center space-x-4 mt-8">
          <Button
            disabled={isSubmitLoading}
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
            endIcon={
              isSubmitLoading && (
                <CircularProgress color="inherit" size="1rem" />
              )
            }
          >
            Submit
          </Button>
          {!!Reflect.ownKeys(errors).length && (
            <p className="text-error-main text-xs">
              You might have skipped some questions.
            </p>
          )}
        </div>
      </form>
    </div>
  );
};

export default AssessmentPage;
