import { yupResolver } from "@hookform/resolvers/yup";
import { Chat, SendRounded } from "@mui/icons-material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { Avatar, Button, Divider, Snackbar } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ChatServiceInstance from "../../services/chatService";
import EmailModal from "../modals/EmailModal";
import Message from "./Message";
import StreamMessage from "./Streaming";

const schema = yup.object().shape({
  message: yup.string().required("Message is required"),
});

const MAX_MESSAGES = 25;

const ChatScreen = () => {
  const chatEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [hideScrollToBottom, setHideScrollToBottom] = useState(true);

  useEffect(() => {
    if (messages.length) {
      localStorage.setItem("messages", JSON.stringify(messages));
      scrollChatToBottom();
    }
  }, [messages]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    const handleScroll = () => {
      const isBottom =
        chatContainer.clientHeight + chatContainer.scrollTop >=
        chatContainer.scrollHeight - 2;
      setHideScrollToBottom(isBottom);
    };

    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    const storedMessages = JSON.parse(localStorage.getItem("messages"));
    if (storedMessages) {
      setMessages(storedMessages);
    } else {
      ChatServiceInstance.senderFunc(
        {
          messages: [],
          newQuestion: "Hi",
        },
        setStreamingMessage
      ).then(() => {});
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollChatToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const prepareTheMessagesArray = (prevMessages, newMessage) => {
    const updatedMessages = [
      ...prevMessages.slice(-MAX_MESSAGES + 1),
      newMessage,
    ];
    return updatedMessages;
  };

  const [streamMessage, setStreamingMessage] = useState({
    isStreaming: false,
    text: "",
  });
  const [loading, setLoading] = useState(false);

  const addNewMessage = async (data) => {
    scrollChatToBottom();
    setLoading(true);
    setMessages((prev) =>
      prepareTheMessagesArray(prev, {
        role: "user",
        content: data.message,
      })
    );
    reset();

    const isOldMessagesFound = localStorage.getItem("messages");

    await ChatServiceInstance.senderFunc(
      {
        messages: JSON.parse(isOldMessagesFound) || [],
        newQuestion: data.message,
      },
      setStreamingMessage
    );

    setLoading(false);
  };

  useEffect(() => {
    if (!streamMessage.isStreaming && streamMessage?.text?.length) {
      setMessages((prev) =>
        prepareTheMessagesArray(prev, {
          role: "assistant",
          content: streamMessage.text,
        })
      );
      setStreamingMessage({ isStreaming: false, text: "" });
    }
  }, [streamMessage.isStreaming]);

  const [exportModalOpen, setExportModalOpen] = useState(false);
  const handleExportChat = () => setExportModalOpen(true);
  const handleStartNewChat = () => {
    localStorage.removeItem("messages");
    setMessages([]);
    ChatServiceInstance.senderFunc(
      {
        messages: [],
        newQuestion: "Hi",
      },
      setStreamingMessage
    ).then(() => {});
  };

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const handleSnackbarOpen = () => setSnackBarOpen(true);
  const handleSnackbarClose = () => setSnackBarOpen(false);

  return (
    <>
      <div className="h-full w-full flex flex-col items-start space-y-4 relative overflow-y-hidden">
        <div className="header flex items-center justify-between w-full space-x-4 h-max">
          <div className="header flex items-center w-auto space-x-4 h-max">
            <img
              className="w-10 object-contain"
              src="/assets/images/bot.png"
              alt=""
            />
            <div className="flex flex-col items-start">
              <div className="font-semibold text-base">Dr. Mini</div>
              <div className="text-xs">Specialize in Psychiatry</div>
            </div>
          </div>
          <div className="space-x-4">
            <button
              className="bg-secondary p-2 px-3 text-sm lg:text-base text-common-white rounded-xl w-auto"
              onClick={handleStartNewChat}
            >
              Start New Chat
            </button>

            <button
              className="bg-secondary p-2 px-3 text-sm lg:text-base text-common-white rounded-xl w-auto"
              onClick={handleExportChat}
            >
              Export Chat
            </button>
          </div>
        </div>
        <Divider flexItem />
        <div className="chat-content w-full h-screen flex flex-col items-center justify-between overflow-y-hidden">
          <div
            className="w-full transition-all overflow-y-auto h-full"
            ref={chatContainerRef}
          >
            {messages.length ? (
              messages.map((eachMessageItem, index) => (
                <Message key={index} {...eachMessageItem} />
              ))
            ) : (
              <DefaultChatScreen />
            )}
            {!streamMessage?.isStreaming && loading && (
              <Message role="assistant" content="Typing" />
            )}
            {streamMessage?.isStreaming && (
              <StreamMessage streamMessage={streamMessage} />
            )}
            <div ref={chatEndRef} />
          </div>

          {(!hideScrollToBottom || window.scrollY > 50) && (
            <button
              className="absolute right-10 bottom-20 bg-primary text-common-white rounded-full px-2 py-1"
              onClick={scrollChatToBottom}
            >
              <ExpandCircleDownIcon
                color="secondary"
                fontSize="small"
                className="-mt-1"
              />
            </button>
          )}

          <form
            onSubmit={handleSubmit(addNewMessage)}
            className="w-full h-max flex items-center space-x-3 bg-paper-main p-3 rounded-xl border-[0.08rem] border-paper-border"
          >
            <input
              {...register("message")}
              name="message"
              multiple
              className="w-full break-all"
              placeholder="Message"
              style={{
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
              }}
            />
            <button
              className="bg-secondary p-2 px-3 flex items-center justify-end text-common-white rounded-xl"
              disabled={!!errors?.message}
              type="submit"
            >
              <SendRounded fontSize="small" />
            </button>
          </form>
        </div>
      </div>
      <EmailModal
        setOpen={setExportModalOpen}
        open={exportModalOpen}
        handleSnackbarOpen={handleSnackbarOpen}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Your messages sent to preferred email."
      />
    </>
  );
};

const DefaultChatScreen = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center opacity-40">
      <div className="w-max p-8 bg-paper-main rounded-full shadow-inner">
        <Chat color="primary" fontSize="large" className="drop-shadow-lg" />
      </div>
      <span>Start a conversation...</span>
    </div>
  );
};

export default ChatScreen;
