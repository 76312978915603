import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const primaryColor = "#118C74";
export const primaryLightColor = "#17CAC0";
export const action = { select: "#17CAC0" };
export const secondaryColor = "#FCB827";
export const textColor = {
  main: "#202020",
  secondary: "#767676",
  question: "#505050",
  answers: "#26262A",
};
export const paper = {
  main: "#F7F7F7",
  border: "#E4E4E4",
};

export const backgroundGradient = {
  primary: primaryColor,
  secondary: "#CBE394",
};

export const errorColor = {
  main: red.A400,
};

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      light: primaryLightColor,
    },
    secondary: {
      main: secondaryColor,
    },
    action: action,
    paper: paper,
    text: textColor,
    error: errorColor,
    backgroundGradient: backgroundGradient,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: "none",
          color: "#ffff",
          textTransform: "none",
          fontWeight: "bolder",

          "&:hover": {
            backgroundColor: primaryColor,
            color: "#ffff",
          },
        },
      },
    },
  },
});

export default theme;
