export function urlify(text) {
  const urlRegex = /(https?:\/\/|www\.)[^\s.,;!?)]+\.?[^\s.,;!?)]*/g;
  return text.replace(urlRegex, function (url) {
    let prefix = url;
    if (prefix.startsWith("www.")) {
      prefix = "http://" + url;
    }
    return '<a href="' + prefix + '" target="_blank">' + url + "</a>";
  });
}

export function markupToText(markup) {
  return markup.replace(/<[^>]*>/g, "");
}
