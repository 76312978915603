import scoreMetadata from "./metadata";

const customScoreCalculator = ({ questions, assessment, results }) => {
  let totalScoreAvailable = 0;
  const questionsAnswers = [];
  let scoreGained = 0;

  let maxWeight = -1;
  questions.forEach((eachQuestion, index) => {
    eachQuestion.options.forEach((eachOption) => {
      if (eachOption.weight > maxWeight) {
        maxWeight = eachOption.weight;
      }
    });
    totalScoreAvailable += maxWeight;
  });

  for (const [questionId, optionId] of Object.entries(assessment)) {
    const question = questions.find(
      (eachQuestion) => eachQuestion._id === questionId
    );
    if (question) {
      const option = question.options.find(
        (eachOption) => eachOption._id === optionId
      );
      if (option) {
        questionsAnswers.push({
          answer_weight: option.weight,
          question: question.title,
          answer: option.optionTitle,
        });

        scoreGained += option.weight;
      }
    }
  }

  const result = {};
  scoreMetadata.forEach((disease, index) => {
    const ranges = disease.ranges;
    ranges.forEach((range) => {
      const score = questionsAnswers
        .slice(range.range.from - 1, range.range.to)
        .filter(
          (questionAnswer) => questionAnswer.answer_weight >= range.scoreGreater
        ).length;
      if (score < range.min) return;

      result[disease.slug] = {
        passedCondition: (result[disease.slug]?.passedCondition || 0) + 1,
      };
    });
  });

  const resultMessages = [];
  Object.entries(result).forEach(([key, value]) => {
    const foundDisease = scoreMetadata.find((disease) => disease.slug === key);
    if (value.passedCondition === foundDisease.ranges.length) {
      resultMessages.push(foundDisease.name);
    }
  });

  const calculatedResult = results.find(
    (eachResult) => scoreGained >= eachResult.more_than
  );

  return {
    totalScoreAvailable,
    scoreGained,
    questionsAnswers,
    calculatedResult: {
      ...calculatedResult,
      emoji: resultMessages.length
        ? "https://mindsync-bucket.s3.amazonaws.com/uploads/image/2023/6/16/7acd431c-0413-4d6c-aae8-43e51bc2f0b6.png"
        : "https://mindsync-bucket.s3.amazonaws.com/uploads/image/2023/7/11/1b0691c0-42b8-4d2f-ba01-c6d9717d8d78.png",
    },
    resultMessage: resultMessages.length
      ? `Based on the assessment you've completed, there is an indication that you may have ${resultMessages.join(
          ", "
        )}. Please note that this result is preliminary, and we recommend consulting with a healthcare professional for a confirmed diagnosis.`
      : "You seem to be fine.",
  };
};

export default customScoreCalculator;
