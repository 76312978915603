import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const AssessmentCard = ({ cardData }) => {
  const navigate = useNavigate();
  return (
    <Box className="md:p-6 p-2 flex sm:items-center items-start justify-between w-full border-[0.1rem] border-paper-border rounded-lg space-x-4">
      <img
        src={cardData.assessment_icon}
        alt={cardData.assessment_title}
        className="md:w-[7rem] md:h-[7rem] max-w-[5rem] max-h-[5rem] rounded-full"
      />
      <div className="flex sm:flex-row sm:justify-between flex-col sm:items-center items-start sm:space-x-4 space-x-0 space-y-4 w-full">
        <div className="flex flex-col itemes-start space-y-2 w-full">
          <div className="sm:text-lg text-base font-bold">
            {cardData.assessment_title}
          </div>
          <div className="w-11/12 text-textColor-secondary font-medium md:text-base text-[0.7rem] break-normal">
            {cardData.assessment_identifier}
          </div>
        </div>
        <Button
          color="secondary"
          variant="contained"
          className="md:w-[15rem] w-[10rem]"
          onClick={() => navigate(`/assessment/${cardData.assessment_slug}`)}
          sx={{ color: "white" }}
        >
          <span className="md:text-base text-xs py-1">Take Assessment</span>
        </Button>
      </div>
    </Box>
  );
};

export default AssessmentCard;
