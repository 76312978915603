import { Button, Hidden } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full bg-paper-main rounded-none flex items-center justify-between py-3 lg:px-[4rem] md:px-12 px-2">
      <Hidden mdDown>
        <img
          onClick={() => navigate("/")}
          src="/assets/logo/logo.svg"
          alt="The Mind Sync"
          style={{ width: "15rem", height: "auto" }}
          className="cursor-pointer"
        />
      </Hidden>

      <Hidden mdUp>
        <img
          src="/assets/logo/logo.svg"
          alt="The Mind Sync"
          style={{ width: "10rem", height: "auto" }}
        />
      </Hidden>
      <div className="flex items-center">
        <Button
          size="medium"
          variant="contained"
          color="secondary"
          sx={{ paddingX: "1rem" }}
          href="https://www.themindsynckids.com/"
          target="_blank"
        >
          <span className="md:text-base text-xs">Book Appointment</span>
        </Button>
      </div>
    </div>
  );
};

export default NavBar;
