import axios from "axios";
import settingsConfig from "./settingConfig";

const axiosInstance = axios.create({
  baseURL: settingsConfig.BASE_API_URL,
});
axiosInstance.interceptors.request.use(
  function configHeader(config) {
    // const token = localStorage.getItem("token");
    // if (token) {
    //   config.headers.Authorization = token;
    // }
    return config;
  },
  function configError(error) {
    return Promise.reject(error);
  }
);
export default axiosInstance;
