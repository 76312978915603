import React from "react";
import Message from "./Message";

const StreamMessage = ({ streamMessage }) => {
  console.log(streamMessage);
  if (!streamMessage.isStreaming) return null;
  return <Message role="assistant" content={streamMessage.text} />;
};

export default StreamMessage;
