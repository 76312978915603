import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="md:h-[80vh] h-[84vh] w-full flex flex-col items-center justify-center bg-common-white space-y-4 px-6">
      <img
        className="md:w-1/4 h-max w-full"
        src="/assets/images/404.svg"
        alt="Not Found"
      />
      <div className="font-bold text-primary lg:text-5xl md:text-3xl text-2xl">
        Oops!
      </div>
      <div className="w-full flex flex-col items-center">
        <div className="font-semibold sm:text-xl text-base text-center">
          Error 404 - Page not found
        </div>
        <div className="text-center sm:text-base text-[0.7rem]">
          The page you requested could not be found.
        </div>
        <div className="text-center sm:text-base text-[0.7rem]">
          We are working on it :)
        </div>
      </div>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={() => navigate("/")}
      >
        Go Back
      </Button>
    </div>
  );
};

export default NotFound;
