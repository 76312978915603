import { Button, CircularProgress } from "@mui/material";
import AssessmentCard from "../components/AssessmentCard";
import BaseHelper from "../components/BaseHelper";
import AssessmentInstance from "../services/assessmentService";
import { useEffect, useState } from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate } from "react-router-dom";
import customAssessments from "../customAssessments/custom.json";

const HomePage = () => {
  const [allAssessments, setAllAssessments] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAllAssessments();
  }, []);

  const fetchAllAssessments = async () => {
    setIsLoading(true);
    const assessments = await AssessmentInstance.getAllAssessments();
    if (assessments.success) {
      const customAssessment = Object.values(customAssessments);
      setAllAssessments([...assessments.data, ...customAssessment]);
      setIsLoading(false);
    }
  };

  return (
    <BaseHelper
      header={<HomePageHeader />}
      content={
        !isLoading ? (
          <HomePageContent allAssessments={allAssessments} />
        ) : (
          <CircularProgress />
        )
      }
      footer={<HomePageFooter />}
    />
  );
};

const HomePageHeader = () => {
  return (
    <div className="flex md:flex-row flex-col items-center w-full h-full justify-around lg:px-24 md:px-12 px-8 py-16 md:space-y-0 space-y-6">
      <div className="content flex flex-col md:items-start items-center md:space-y-6 space-y-4">
        <div className="text-paper-main lg:text-5xl md:text-3xl text-2xl font-semibold md:text-start text-center">
          Take a <span className="text-secondary">Mental Health</span>{" "}
          <span>Test</span>
        </div>
        <div className="text-paper-main md:w-2/3 w-full font-medium lg:text-lg md:text-base text-xs md:text-start text-center">
          Choose from a variety of research based assessments that screen for
          different mental health concerns.
        </div>
      </div>
      <div className="media">
        <img src="/assets/images/characters.svg" alt="Take A Test" />
      </div>
    </div>
  );
};

const HomePageContent = ({ allAssessments }) => {
  return (
    <div className="flex flex-col items-center sm:space-y-12 space-y-6">
      {allAssessments.length ? (
        <>
          {allAssessments.map((eachData) => (
            <AssessmentCard cardData={eachData} />
          ))}
        </>
      ) : (
        <>
          <AssignmentIcon />
          There are no active tests !
        </>
      )}
    </div>
  );
};

const HomePageFooter = () => {
  const navigate = useNavigate();
  return (
    <div className="flex lg:flex-row flex-col items-center justify-between lg:space-y-0 space-y-8">
      <div className="flex flex-col lg:items-start items-center lg:space-y-8 space-y-4">
        <div className="title lg:text-5xl md:text-3xl text-xl font-semibold text-center text-primary">
          How does it work?
        </div>
        <div className="description font-medium md:w-2/3 w-full lg:text-lg text-sm lg:text-start text-center">
          After your mental health test, you will see information, resources,
          and tools to help you understand and improve your mental health.
        </div>
        <div className="flex items-center space-x-4">
          <Button
            color="secondary"
            variant="contained"
            size="large"
            sx={{
              fontWeight: "medium",
            }}
            onClick={() => navigate("/chat")}
          >
            <span className="px-4 text-paper-main">Chat with us</span>
          </Button>
          <div className="font-semibold">It's Free</div>
        </div>
      </div>
      <div className="xs:w-max w-[20rem]">
        <img src="/assets/images/footer.svg" alt="Footer" />
      </div>
    </div>
  );
};

export default HomePage;
