import moment from "moment";
import React from "react";
import { urlify } from "../../utils/helpers";

const Message = ({ content, role }) => {
  return (
    <div className="flex flex-col flex-grow-0 flex-shrink-0 items-start justify-end w-full relative">
      <div
        className={`flex w-full ${
          role === "assistant" ? "items-start" : "flex-row-reverse"
        }`}
      >
        <div className="mx-2">
          {role === "assistant" && (
            <img
              className="mx-2 w-10 object-contain rounded-full bg-gray-800"
              src="/assets/images/bot.png"
              alt=""
            />
          )}
        </div>
        <div
          className={`flex flex-col w-full ${
            role === "assistant" ? "items-start" : "items-end"
          }`}
        >
          <div
            className={`${
              role === "assistant"
                ? "bg-primary rounded-b-xl rounded-r-xl text-common-white"
                : "bg-paper-main rounded-b-xl rounded-l-xl md:ml-auto"
            } py-2 px-4 shadow w-auto max-w-lg md:text-base sm:text-sm text-xs`}
          >
            <div
              className="break-word whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: urlify(content) }}
            />
          </div>
          <div className="time-stamp lg:text-[0.7rem] md:text-[0.5rem] text-[0.45rem] px-3 py-1 text-textColor-secondary drop-shado">
            {moment(new Date()).format("D-MM-YYYY hh:mm A")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
