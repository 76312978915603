import { ArrowBackRounded } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React from "react";
import ChatScreen from "../components/chat/ChatScreen";
import { useNavigate } from "react-router-dom";

const Chat = () => {
  const navigate = useNavigate();
  return (
    <div className="h-[87vh] bg-common-white flex flex-col w-full md:px-12 px-2 pb-4">
      <button
        className="py-3 text-base cursor-pointer w-max hover:text-primary"
        onClick={() => navigate("/")}
      >
        <ArrowBackRounded fontSize="small" className="-mt-1" /> Back
      </button>
      <Grid container className="w-full h-full max-h-[78vh]">
        {/* Chat side bar is disabled for now */}
        {/* <Grid
          item
          xs={3}
          className="bg-paper-main rounded-l-lg border-[0.08rem] border-paper-border max-h-full overflow-hidden"
        >
          <ChatSideBar />
        </Grid> */}
        <Grid
          item
          xs={12}
          className="border-[0.08rem] border-paper-border rounded-lg md:p-5 sm:p-4 p-2 h-full max-h-full overflow-y-hidden"
        >
          <ChatScreen />
        </Grid>
      </Grid>
    </div>
  );
};

export default Chat;
