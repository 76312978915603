import axios from "axios";
import settingsConfig from "./settingConfig";

class ChatServiceService {
  senderFunc = async (body, setStreamingMessage) => {
    try {
      const response = await fetch(`${settingsConfig.BASE_API_URL}/messages`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.status !== 200) {
        const data = await response.json();
        throw new Error(data.error);
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          setStreamingMessage((prev) => ({
            ...prev,
            isStreaming: false,
          }));
          break;
        }
        const textChunk = decoder.decode(value);
        console.log(textChunk);
        setStreamingMessage((prev) => ({
          isStreaming: true,
          text: prev.text + textChunk,
        }));
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

  exportChat = (body) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${settingsConfig.BASE_API_URL}/messages/export`, body)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          // console.log(error);
          reject(error);
        });
    });
  };

  exportAssessment = (body) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${settingsConfig.BASE_API_URL}/assessment/export`, body)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          // console.log(error);
          reject(error);
        });
    });
  };
}

const ChatServiceInstance = new ChatServiceService();
export default ChatServiceInstance;
