import {
  Button,
  Card,
  CardMedia,
  Divider,
  Hidden,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BaseHelper from "../components/BaseHelper";
import AssessmentModal from "../components/modals/AssessmentModal";
import { primaryColor } from "../themeConfig";
import Typewriter from "typewriter-effect";
import { markupToText } from "../utils/helpers";

const Result = () => {
  const location = useLocation();
  const { state } = location;
  // const navigate = useNavigate();

  return <BaseHelper header={<ResultsPageHeader resultData={state} />} />;
};

const ResultsPageHeader = ({ resultData }) => {
  const navigate = useNavigate();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const handleSnackbarOpen = () => setSnackBarOpen(true);
  const handleSnackbarClose = () => setSnackBarOpen(false);
  const [assessmentDialog, setAssessmentDialog] = useState(false);
  const handelOpenDialog = () => setAssessmentDialog(true);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const resultMessage = resultData?.resultMessage
    ? resultData?.resultMessage.trim()
    : resultData?.calculatedResult?.answer.trim();

  return (
    <>
      <div className="flex flex-col items-center w-full h-fit  space-y-10 lg:px-24 md:px-12 px-6 my-6">
        <div className="md:text-[2.5rem] text-2xl font-semibold mt-8 text-center">
          <span className="text-secondary">
            Your {resultData?.assessmentData.assessment_title}
          </span>{" "}
          <span className="text-paper-main">Results</span>
        </div>
        {/* -----------Result Box-------------- */}
        <div className="bg-[#F7F7F725] w-full flex md:flex-row flex-col items-center rounded-2xl py-8 md:space-y-0 space-y-8">
          {/* -----------Left Content---------- */}
          <div className="flex flex-col items-center w-full justify-between space-y-6">
            <div className="bg-[#F7F7F740] rounded-full p-5">
              <img
                className="md:w-auto md:h-auto w-[4.5rem] h-[4.5rem]"
                src={resultData?.calculatedResult?.emoji}
                alt={resultData?.calculatedResult?.comment}
              />
            </div>
            <div className="flex flex-col items-center">
              <div className="text-4xl text-secondary font-semibold">
                You scored {resultData?.scoreGained}
              </div>
              <div className="text-paper-main text-lg font-semibold">
                OUT OF {resultData?.totalScoreAvailable}
              </div>
            </div>

            <div className="flex items-center space-x-3 w-max">
              <img
                className="w-10 object-contain rounded-full bg-gray-800"
                src="/assets/images/bot.png"
                alt=""
              />
              <div
                style={{ width: "30rem" }}
                className="md:text-lg text-xs font-normal max-w-full text-common-white"
              >
                <Typewriter
                  options={{
                    strings: [resultMessage],
                    autoStart: true,
                    loop: false,
                    delay: "natural",
                    pauseFor: 60 * 60 * 12,
                  }}
                />
              </div>
            </div>
            {resultData?.questionsAnswers?.length && (
              <Button
                color="secondary"
                variant="contained"
                size="large"
                sx={{ paddingX: "2rem" }}
                onClick={handelOpenDialog}
              >
                Get your results on email!
              </Button>
            )}
          </div>
          {/* --------------------------------- */}

          {/* ---Vertical Divider--- */}
          <Hidden smDown>
            <Divider
              orientation="vertical"
              flexItem
              className="bg-paper-main opacity-30"
            />
          </Hidden>
          {/* ---------------------- */}

          {/* -----------Right Content---------- */}
          <div className="flex flex-col items-center w-full justify-between space-y-6 px-4 min-h-max">
            {/* <img src="/assets/images/characters.svg" alt="Doctor's Video" /> */}
            <Card sx={{ maxWidth: "30rem" }}>
              <CardMedia
                controls
                component="video"
                src={resultData?.calculatedResult.doctors_video.s3Url}
              />
            </Card>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              sx={{ paddingX: "2rem" }}
              href="https://www.themindsynckids.com/"
              target="_blank"
            >
              Book An Appointment Now
            </Button>
          </div>
          {/* ---------------------------------- */}
        </div>
        {/* ------------------------------------ */}
        <div className="w-11/12 flex flex-col items-center space-y-4 text-paper-main ">
          <div style={{ fontSize: "15px" }} className="md:text-lg opacity- 90">
            <strong>Disclaimer:</strong> These results are not meant to be a
            diagnosis. You can meet with a doctor or therapist to get a
            diagnosis and/or access therapy or medications. Sharing these
            results with someone you trust can be a great place to start. Below
            are programs you can join.
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ paddingX: "2rem" }}
            href="https://www.themindsynckids.com/"
            target="_blank"
          >
            Join Free Mental Health Education Programme With An Instructor
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ paddingX: "2rem" }}
            href="https://www.themindsynckids.com/"
            target="_blank"
          >
            Free SEL programme for your child
          </Button>
        </div>
        <div className="flex items-center space-x-4">
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ paddingX: "2rem" }}
            onClick={() => navigate("/chat")}
          >
            Chat with us
          </Button>
          <Button
            size="large"
            variant="outlined"
            sx={{
              paddingX: "2rem",
              borderColor: "white",
              color: "white",
              "&:hover": { backgroundColor: "white", color: primaryColor },
            }}
            href="https://www.themindsynckids.com/"
            target="_blank"
          >
            Contact us
          </Button>
        </div>
      </div>
      <AssessmentModal
        setOpen={setAssessmentDialog}
        open={assessmentDialog}
        handleSnackbarOpen={handleSnackbarOpen}
        assessment={{
          assessment: resultData?.questionsAnswers,
          scoreGained: resultData?.scoreGained,
          totalScoreAvailable: resultData?.totalScoreAvailable,
          comment: markupToText(resultMessage),
        }}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Your Assessment results sent to preferred email."
      />
    </>
  );
};

export default Result;
