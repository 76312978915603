import React from "react";

const BaseHelper = (props) => {
  const { header = null, content, footer = null } = props;

  return (
    <div className="w-full bg-common-white min-h-screen m-0 ">
      {header && (
        <div className="header lg:bg-gradient-to-r bg-gradient-to-b from-bgGradiant-primary from-45% to-bgGradiant-secondary min-h-max flex items-center">
          <div className="w-full">{header}</div>
        </div>
      )}
      {content && (
        <div className="bg-transparent min-h-max lg:p-16 p-4">{content}</div>
      )}
      {footer && (
        <div className="bg-transparent min-h-max lg:p-16 p-4">{footer}</div>
      )}
    </div>
  );
};

export default BaseHelper;
