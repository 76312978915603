import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/Home";
import NavBar from "./components/navBar";
import Result from "./pages/Result";
import AssessmentPage from "./pages/Assessment";
import NotFound from "./pages/notFound";
import Footer from "./components/Footer";
import Chat from "./pages/Chat";

function App() {
  return (
    <BrowserRouter>
      <div className="bg-primary pt-8 min-h-screen font-montserrat">
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/assessment/:slug" element={<AssessmentPage />} />
          <Route path="/result/:assessment" element={<Result />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
