import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import ChatServiceInstance from "../../services/chatService";
import { FormHelperText } from "@mui/material";

const AssessmentModal = ({ open, setOpen, handleSnackbarOpen, assessment }) => {
  const [email, setEmail] = useState("");
  const handleCancel = () => setOpen(false);
  const handleOnSubmit = (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    setOpen(false);
    const assessmentObject = {
      ...assessment,
      assessment: assessment.assessment.map(({ question, answer }) => ({
        question,
        answer,
      })),
    };
    ChatServiceInstance.exportAssessment({
      assessment: assessmentObject,
      email,
    })
      .then(() => {
        handleSnackbarOpen?.();
      })
      .catch((err) => {
        alert("Unable to send messages to the email. Please try again later.");
        console.log(err?.response?.data || err?.message);
      })
      .finally(() => {
        setOpen(false);
        setEmail("");
      });
  };
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>Export Assessment</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleOnSubmit}>
          <input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            name="email"
            type="email"
            className="w-full break-all bg-paper-main  p-3 rounded-xl border-[0.08rem] border-paper-border"
            placeholder="Enter your email here..."
            style={{
              border: "none",
              outline: "none",
            }}
          />
          <FormHelperText>
            If you haven't received any mail, make sure to check the SPAM folder
          </FormHelperText>
          <div className="space-x-3 my-5">
            <button
              className={`${
                email?.length ? "opacity-100" : "opacity-50"
              } bg-primary p-2 px-3 text-common-white rounded-xl`}
              type="submit"
              disabled={!email?.length}
            >
              Send Email
            </button>
            <button
              type="button"
              className="bg-error p-2 px-3 text-common-white rounded-xl"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AssessmentModal;
