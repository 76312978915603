import axiosInstance from "./axiosConfig";

class AssessmentService {
  getAllAssessments = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/assessment/assessments")
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getAssessmentBySlug = (assessmentSlug) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/assessment/${assessmentSlug}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          resolve(error);
        });
    });
  };
}

const AssessmentInstance = new AssessmentService();
export default AssessmentInstance;
